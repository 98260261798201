define(['app', 'accessibilityFocusHelper','$window'], (app, accessibilityFocusHelper,$window) => {
  const skipLink = () => {
    const component = {};
    const _config = {
      selectors: {
        skipToMainContent: '[data-skip-link=skipToMainContent]',
        skipToMainContentPreferredTarget: '#main-content',
        skipLinkBackupTarget: '#mainContent',
        mainTag: 'main',
        skipToAlert: '[data-skip-link=skipToAlert]',
        alert: '[data-alert=success], [data-alert=error], [data-alert=upsell]',
      },
      classNames: {
        skipLinkClass: 'skipLink_link',
        westendHeader: '.westendHeader',
      },
      attributes: {
        skipToAlertText: 'skip-to-alert-text',
      }
    };

    const _init = element => {
      if (!element) return;

      component.element = element;

      component.element.addEventListener('focusin', component.trackFocus);

      component.skipToMainContent = component.element.querySelector(component.config.selectors.skipToMainContent);

      const alerts = document.querySelectorAll(component.config.selectors.alert);

      if (alerts.length > 0) {
        component.skipToAlert = component.createSkipLink({
          name: 'skipToAlert',
          href: '#alert',
          text: component.element.getAttribute(component.config.attributes.skipToAlertText) || 'Skip To Page Alerts',
          clickEventListener: event => {
            event.preventDefault();
            component.alertTarget = component.getAlertTarget(alerts);
            component.skipToTarget(component.alertTarget);
          }
        });
        component.element.insertBefore(component.skipToAlert, component.skipToMainContent);
      }

      // skip to main content
      component.mainContentTarget = component.getMostSuitableSkipTarget();

      // If target element exists then add event listener
      if (component.mainContentTarget) {
        component.skipToMainContent.addEventListener('click', event => {
          event.preventDefault();
          component.skipToTarget(component.mainContentTarget);
        });
      } else {
        component.skipToMainContent.remove();
        // remove skip link if there is no target
        !component.skipToAlert && component.element.remove();
      }
    };

    const _getAlertTarget = (alerts) => {
      let i = 0;
      let targetAlert = alerts[i];
      while (targetAlert && $window.getComputedStyle(targetAlert).display === 'none') {
        i ++;
        targetAlert = alerts[i];
      }
      return targetAlert;
    };

    const _getMostSuitableSkipTarget = () => {
      return document.querySelector(component.config.selectors.skipToMainContentPreferredTarget)
        || document.querySelector(component.config.selectors.skipLinkBackupTarget)
        || document.querySelector(component.config.selectors.mainTag)
        || null;
    };

    const _createSkipLink = config => {
      const link = document.createElement('a');
      link.setAttribute('class', component.config.classNames.skipLinkClass);
      link.setAttribute('href', config.href);
      link.setAttribute('data-skip-link', config.name);
      link.innerText = config.text;
      link.addEventListener('click', config.clickEventListener);
      return link;
    };

    const _skipToTarget = target => {
      component.trackClick();
      accessibilityFocusHelper.focus(target);
      target.scrollIntoView();
      const westendHeader = document.querySelector(component.config.classNames.westendHeader);
      westendHeader && window.scrollBy(0, -westendHeader.clientHeight);
    };

    const _trackFocus = () => {
      app.publish('columbo/track', 'Accessibility', 'Focused', 'SkipLink');
    };

    const _trackClick = () => {
      app.publish('columbo/track', 'Accessibility', 'Clicked', 'SkipLink');
    };

    component.config = _config;
    component.init = _init;
    component.getMostSuitableSkipTarget = _getMostSuitableSkipTarget;
    component.skipToTarget = _skipToTarget;
    component.trackFocus = _trackFocus;
    component.trackClick = _trackClick;
    component.createSkipLink = _createSkipLink;
    component.getAlertTarget = _getAlertTarget;
    return component;
  };

  return skipLink;
});
